import * as React from "react";
import Layout from "../components/Layout";

const IndexPage = () => {
  return (
    <Layout>
      <main className="container mx-auto">
        <div>

        </div>
      </main>
    </Layout>
  );
};

export default IndexPage;
